import { useEffect, useState } from "react";
import {
  Main,
  DateBox,
  Container,
  ImageMine,
  ImageNotMine,
  IconNotMine,
  IconMine,
  ContainerMessage,
  DownloadMessage,
  TimeDifference,
  TooltipContent,
  TooltipArrow,
} from "./styles";
import { formatTimeSent, formatDate, extractImage } from "../../../../../../utils/format";
import * as Tooltip from "@radix-ui/react-tooltip";
import { useSession } from "../../../../../../hook/session";
import imageContato from "../../../../../../assets/images/imageContato";

const Message = ({ message, socket, dataContact }) => {
  const { sessionMedia, setSessionMedia } = useSession();
  const [mediaSrc, setMediaSrc] = useState(null);
  const [mediaClicked, setMediaClicked] = useState(false);

  const getBase64Media = async (fileName, fileType) => {
    return new Promise((resolve, reject) => {
      socket.emit("getBase64Media", fileName, fileType, (err, res) => {
        if (!err) {
          resolve(res);
        } else {
          reject(err);
        }
      });
    });
  };

  const getImageSession = async () => {
    return new Promise((resolve, reject) => {
      socket.emit("getSessionInfo", (res) => {
        getBase64Media(res.image_filename_session, res.image_filetype_session)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  };

  useEffect(() => {
    if (mediaClicked && message.fileName && message.fileType) {
      getBase64Media(message.fileName, message.fileType)
        .then((res) => {
          setMediaSrc(res);
        })
        .catch((err) => {
          console.error("Error fetching media:", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaClicked, message.fileName, message.fileType]);

  useEffect(() => {
    getImageSession()
        .then((res) => {
          setSessionMedia(res);
        })
        .catch((err) => {
          console.error("Error fetching session media:", err);
        });
  },);

  const handleMediaClick = () => {
    setMediaClicked(true);
  };

  const renderMedia = () => {
    if (!mediaSrc && message.fileType === "data:image/jpeg;base64") {
      return <p>Clique para carregar imagem</p>;
    } else if (!mediaSrc && message.fileType === "data:video/mp4;base64") {
      return <p>Clique para carregar video</p>;
    } else if (!mediaSrc && message.fileType.startsWith("data:audio")) {
      return <p>Clique para carregar áudio</p>;
    } else if (!mediaSrc) {
      return <p>Clique para carregar arquivo</p>;
    }
    if (message.fileType.startsWith("data:image")) {
      return (
        <img
          src={mediaSrc}
          alt="media message"
          style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
        />
      );
    }
    if (message.fileType.startsWith("data:video")) {
      return (
        <video
          src={mediaSrc}
          controls
          style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
        />
      );
    }
    if (message.fileType.startsWith("data:audio")) {
      return (
        <audio
          src={mediaSrc}
          controls
          style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
        />
      );
    }
    return (
      <div className="media-message">
        <a href={mediaSrc} download={message.fileName}>
          Fazer Download do arquivo
        </a>
      </div>
    );
  };

  return (
    <Main>
      <DateBox $isFlag={message.isFlag}>
        <p>{formatDate(message.date)}</p>
      </DateBox>
      <Container $isFlag={message.isFlag} $isMine={message.isMine} >
        {extractImage(dataContact.image) !== 'null' ? (
            <ImageNotMine $isMine={message.isMine} src={dataContact.image} alt="Imagem do contato" />
          ) : (
            <ImageNotMine $isMine={message.isMine} src={imageContato} alt="Imagem do contato" />
          )}
        <IconNotMine $isMine={message.isMine} />
        <ContainerMessage
          $isMine={message.isMine}
          $isNoteMessage={message.isNoteMessage}
        >
          {message.fileType &&
            (message.fileType.startsWith("image/") ||
              message.fileType.startsWith("video/") ||
              message.fileType.startsWith("audio/") ||
              message.fileType) && (
              <DownloadMessage
                className="media-message"
                onClick={handleMediaClick}
              >
                {renderMedia()}
              </DownloadMessage>
            )}
          {message.message && <p>{message.message}</p>}
          <TimeDifference>
            {formatTimeSent(message.sendDateMessage)}
          </TimeDifference>
        </ContainerMessage>
        <IconMine
          $isMine={message.isMine}
          $isNoteMessage={message.isNoteMessage}
        />
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <ImageMine
                $isMine={message.isMine}
                src={sessionMedia ? sessionMedia : "logojit.jpg"}
                style={{
                  cursor: "pointer",
                }}
              />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <TooltipContent className="TooltipContent" sideOffset={5}>
                {message.userName ? (
                  <p>Enviado por {message.userName}</p>
                ) : (
                  <p>Impossível determinar autor</p>
                )}
                <TooltipArrow />
              </TooltipContent>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </Container>
    </Main>
  );
};

export default Message;
