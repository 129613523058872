import UserApi from "./api/UserApi";

class UserService {
  async getUsers() {
    try {
      const response = await UserApi.getUsers();
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async createUser(data) {
    try {
      const response = await UserApi.createUser(data);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async deleteUser(id) {
    try {
      const response = await UserApi.deleteUser(id);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new UserService();
