import {
  Container,
  HeaderConfig,
  BottomConfig,
  ImageContact,
  IconsEnd,
  PopoverContent,
  PopoverArrow,
  Item,
  ItemText,
} from './styles'
import Input from '../../../../../../shared/components/Input'
import FilterListIcon from '@mui/icons-material/FilterList'
import ChatIcon from '@mui/icons-material/Chat'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import * as Popover from '@radix-ui/react-popover';
import LoginService from '../../../../../../shared/services/Login/LoginService'
import LogoJit from '../../../../../../assets/images/logojit.jpg'
import { useSession } from '../../../../../../hook/session'

const SearchMenu = ({ setSearchTerm }) => {
  const { sessionMedia } = useSession()

  const handleLogout = () => {
    LoginService.logout()
  }

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleButtonClick = () => {
     window.location.href = '/'
  }

  return (
    <Container>
      <HeaderConfig>
        <ImageContact src={sessionMedia || LogoJit} />
        <IconsEnd>
          <ChatIcon
            fontSize="large"
            style={{ color: 'white', marginLeft: '10px' }}
            cursor="pointer"
            onClick={handleButtonClick}
          />
          <Popover.Root>
            <Popover.Trigger asChild>
                <MoreVertIcon
                  fontSize="large"
                  style={{ color: 'white', marginLeft: '10px' }}
                  cursor="pointer"
                /> 
            </Popover.Trigger>
            <Popover.Portal>
              <PopoverContent className="PopoverContent" sideOffset={5}>
                <Item onClick={handleLogout}>
                  <ItemText>Sair</ItemText>
                </Item>
                <PopoverArrow className="PopoverArrow" />
              </PopoverContent>
            </Popover.Portal>
          </Popover.Root>
        </IconsEnd>
      </HeaderConfig>
      <BottomConfig>
        <Input
          placeholder="Pesquisar"
          style={{ marginLeft: '5px' }}
          onChange={handleInputChange}
        />
        <FilterListIcon
          style={{ color: 'gray', marginLeft: '10px', marginTop: '-10px' }}
        />
      </BottomConfig>
    </Container>
  )
}

export default SearchMenu