import styled from "styled-components";
import {Trigger, Content, Item} from '@radix-ui/react-select';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 1rem;
    width: 100%;
    height: 100%;
`;

export const Header = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    gap: 8px;
`;

export const Title = styled.h1`
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.Text};
`;

export const Subtitle = styled.h2`
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.Text};
`;

export const ContainerConfig = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${({ theme }) => theme.colors.Primary};
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const SelectTrigger = styled(Trigger)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.MainScreen};
    color: ${({ theme }) => theme.colors.Text};
    border: 1px solid ${({ theme }) => theme.colors.Outline};   
    border-radius: 8px;
    padding: 8px;
    gap: 8px;
    cursor: pointer;
`;

export const SelectContent = styled(Content)`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.MainScreen};
    border-radius: 8px;
    padding: 8px;
    gap: 8px;
    z-index: 999;
    margin-left: 58.5vw; 
    border: 1px solid ${({ theme }) => theme.colors.Outline};
`;

export const SelectItem = styled(Item)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    color: ${({ theme }) => theme.colors.Text};
    &:hover {
        background-color: ${({ theme }) => theme.colors.WhiteHover};
    }
`;