export default {
    colors: {
        Primary: '#444',
        BackgroundMessage: '#333',
        BackgroundMessageStrong: '#7863DE',
        BackgroundMessageStrongOverflow: '#504394',
        Outline: '#f0f0f0',
        Text: '#fff',
        TextHover: '#444',
        Alert: '#ff6b6b',
        Success: '#00ff00',
        Warning: '#ffcc00',
        Jit: '#5000ff',
        JitHover: '#4d00e6',
        TextOnJit: '#fff',
        White: '#fff',
        OnPrimary: '#242424',
        DividerContact: '#111',
        WhiteHover: '#181818',
        MainScreen: '#333',
        Popover: '#444',
        PopoverHover: '#333',
        ImageBackground: '#444',
        Track: '#444',
        Thumb: '#999',
        Tooltip: '#242424',
        SelectedContact: '#afa2eb',
        Note: 'orange',
        Button: '#afa2eb'
    },
}