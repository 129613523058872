import { useEffect, useState } from 'react'
import imageContato from '../../../../../../assets/images/imageContato'
import { extractImage, formatLastMessageTimeStamp } from '../../../../../../utils/format'
import {
  BlocoContact,
  DivRow,
  DivColumn,
  ContactImage,
  CountMessages,
  LastIcons,
  ImageIconWrapper,
  Div,
} from './styles'
import ImageIcon from '@mui/icons-material/Image'


const ContactLateral = ({
  contact,
  selectedContact,
  lastMessage,
  onContactClick,
}) => {

  const [unreadMessages, setUnreadMessages] = useState(contact.unreadMessageCount)

  useEffect(() => {
    if(unreadMessages < contact.unreadMessageCount) {
      if(selectedContact !== contact.id) {
        setUnreadMessages(contact.unreadMessageCount)
      }
    }
  }, [contact.id, contact.unreadMessageCount])

  useEffect(() => {
    if (selectedContact === contact.id) {
      setUnreadMessages(0)
    }
  }, [selectedContact, contact.id])

  return (
    <BlocoContact onClick={() => onContactClick(contact.id)} $isSelected={selectedContact === contact.id ? 'true' : 'false'}>
      <DivRow>
      {extractImage(contact.image) !== 'null' ? (
          <ContactImage src={contact.image} alt="Imagem do contato" />
        ) : (
          <ContactImage src={imageContato} alt="Imagem do contato" />
        )}
        <DivColumn>
          <p>{contact.name}</p>
          <Div $isSelected={selectedContact === contact.id ? 'true' : 'false'}>
            {lastMessage === '' ? (
              <ImageIconWrapper>
                <span>Nova conversa</span>
              </ImageIconWrapper>
            ) : (
              lastMessage
            )}
          </Div>
        </DivColumn>
        <LastIcons>
          <p>{formatLastMessageTimeStamp(contact.lastMessageTimestamp)}</p>
          {unreadMessages > 0 && (
            <CountMessages></CountMessages>
          )}
        </LastIcons>
      </DivRow>
    </BlocoContact>
  )
}

export default ContactLateral
