import { useCallback, useEffect } from 'react'
import { Container, DialogContainer, DialogOverlay, Header, Title, Label, Button, ButtonCadastro, DivButton, Form, ErrorSpan, Checkbox, CheckboxContainer } from './styles'
import * as DialogRadix from '@radix-ui/react-dialog'
import { X } from 'phosphor-react'
import Input from '../../../../../../../../shared/components/InputForm/index.jsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

const Dialog = ({ open, onChangeOpen, showIconClose = true, update, setUpdate, user }) => {
    const userScheme = z.object({
        fullNameContact: z.string().min(1, 'Nome é obrigatório'),
        emailUser: z.string().email('Email inválido').min(1, 'Email é obrigatório'),
        passwordUser: z.string().min(8, 'A senha deve ter no mínimo 8 caracteres'),
        isRootUser: z.boolean(),
    })

    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        mode: 'all',
        resolver: zodResolver(userScheme),
    })

    useEffect(() => {
        if (user) {
            setValue('fullNameContact', user.full_name_contact)
            setValue('emailUser', user.email_user)
            setValue('passwordUser', user.password_user)
            setValue('isRootUser', user.is_root_user)
        }
    }, [user, setValue])

    useEffect(() => {
        if (!open) {
            reset()
        }
    }, [open, reset])

    const handleCloseDialog = useCallback(() => {
        onChangeOpen(false)
    }, [onChangeOpen])

    const onSubmit = async (data) => {
        console.log(data)
    }

    return (
        <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
            <DialogRadix.Portal>
                <DialogRadix.Overlay asChild>
                    <DialogOverlay>
                        <DialogRadix.Content asChild>
                            <DialogContainer>
                                <Container>
                                    <Header>
                                        <Title>Editar usuário:</Title>
                                        <DialogRadix.Close asChild>
                                            {showIconClose && (
                                                <X
                                                    onClick={handleCloseDialog}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            )}
                                        </DialogRadix.Close>
                                    </Header>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Label>Nome:</Label>
                                        <Input
                                            type='text'
                                            placeholder='Nome'
                                            {...register('fullNameContact')}
                                        />
                                        <ErrorSpan $span={!!errors.fullNameContact?.message}>{errors.fullNameContact?.message}</ErrorSpan>
                                        <Label>Email:</Label>
                                        <Input
                                            type='email'
                                            placeholder='Email'
                                            {...register('emailUser')}
                                        />
                                        <ErrorSpan $span={!!errors.emailUser?.message}>{errors.emailUser?.message}</ErrorSpan>
                                        <Label>Senha:</Label>
                                        <Input
                                            type='password'
                                            placeholder='Senha'
                                            {...register('passwordUser')}
                                        />
                                        <ErrorSpan $span={!!errors.passwordUser?.message}>{errors.passwordUser?.message}</ErrorSpan>
                                        <CheckboxContainer>
                                            <Label>É Administrador?</Label>
                                            <Checkbox
                                                type='checkbox'
                                                {...register('isRootUser')}
                                            />
                                        </CheckboxContainer>
                                        <ErrorSpan $span={!!errors.isRootUser?.message}>{errors.isRootUser?.message}</ErrorSpan>
                                        <DivButton>
                                            <Button onClick={handleCloseDialog}>Cancelar</Button>
                                            <ButtonCadastro type='submit'>Editar</ButtonCadastro>
                                        </DivButton>
                                    </Form>
                                </Container>
                            </DialogContainer>
                        </DialogRadix.Content>
                    </DialogOverlay>
                </DialogRadix.Overlay>
            </DialogRadix.Portal>
        </DialogRadix.Root>
    )
}

export default Dialog
