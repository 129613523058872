import styled from 'styled-components'

export const BlocoContact = styled.div`
  width: 100%;
  height: 70px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.DividerContact};
  //background-color: ${({ theme }) => theme.colors.ImageBackground};
  background-color: ${({ theme, $isSelected }) =>
    $isSelected === 'true' ? theme.colors.SelectedContact : theme.colors.ImageBackground};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.WhiteHover};
  }
`

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
`

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  > p {
    color: ${({ theme }) => theme.colors.Text};
  }
`

export const ContactImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.DividerContact};
`

export const CountMessages = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.Alert};
  color: ${({ theme }) => theme.colors.Text};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LastIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
`

export const ImageIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.Outline};

  span {
    margin-left: 5px;
    font-size: 14px;
  }
`

export const Div = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.3s;
  color: ${({ theme, $isSelected }) =>
    $isSelected === 'true' ? theme.colors.Text : theme.colors.SelectedContact};
`