import styled from 'styled-components'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SearchIcon from '@mui/icons-material/Search'
import { CaretLeft } from 'phosphor-react'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 10%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.Primary};
  justify-content: space-between;
  padding: 0 1rem 0 1rem;
`

export const ContactImage = styled.img`
  width: auto;
  height: 80%;
  border-radius: 50%;
  background-color: white;
  margin: 0.5rem;
  z-index: 4000;
`

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`

export const DivColumn = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
`

export const NameContact = styled.h1`
  font-size: 1.5rem;
  font-weight: 800;
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const IconsEnd = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
`

export const ModalImage = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
`

export const EditButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.Button};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all; 

  &:hover {
    opacity: 0.8;
  }
`

export const ModalImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalNameSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalNumberSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.Text};
`

export const InputField = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.Outline};
  border-radius: 5px;
  width: 200px;
  text-align: center;
`

export const InputLabel = styled.label`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.Text};
  margin-bottom: 5px;
  display: block;
`
export const InputBox = styled.input`
  padding: 0.5rem 1rem; /* Adjust padding as needed */
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.Outline};
  border-radius: 5px;
  width: 200px; /* Adjust width as needed */
  text-align: center; /* Adjust alignment if needed */
  box-sizing: border-box; /* Ensures padding doesn't affect width */
  outline: none; /* Removes default outline */
  color: ${({ theme }) => theme.colors.Text}; /* Text color */
  background-color: ${({ theme }) => theme.colors.WhiteHover}; /* Background color */
  transition: all 0.3s; /* Add transition for hover effects */

  &:focus {
    border-color: ${({ theme }) => theme.colors.Jit}; /* Add a focus indicator */
  }
`

export const MoreVertIconStyled = styled(MoreVertIcon)`
  font-size: large;
  margin-left: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
`

export const SearchIconStyled = styled(SearchIcon)`
  font-size: large;
  margin-left: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
`

export const CaretContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const CaretLeftStyled = styled(CaretLeft)`
  display: none;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;

  @media (max-width: 800px) {
    display: flex;
  }
`