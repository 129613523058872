import React from 'react'
import { BackButton, Container } from './styles.js'
import * as Tabs from '@radix-ui/react-tabs';
import { CaretLeft } from 'phosphor-react';
import Geral from './components/Geral'
import Gerenciamento from './components/Gerenciamento';

const MainScreen = ({tab, setTab, navigation, setNavigation}) => {
  return (
    <Container $navigation={navigation}>
      <BackButton onClick={() => setNavigation("configList")}>
        <CaretLeft size={32} weight="bold" />
      </BackButton>
      <Tabs.Root value={tab} onValueChange={setTab}>
        <Tabs.Content value="1">
          <Geral />
        </Tabs.Content>
        <Tabs.Content value="2">
          <Gerenciamento />
        </Tabs.Content>
        <Tabs.Content value="3">Notificações</Tabs.Content>
        <Tabs.Content value="4">Anotações</Tabs.Content>
        <Tabs.Content value="5">Segurança</Tabs.Content>
        <Tabs.Content value="6">Sobre</Tabs.Content>
      </Tabs.Root>
    </Container>
  )
}

export default MainScreen