import React, { useRef } from "react";
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
  UploadButton,
  ModalImage,
} from "./styles";

const Modal = ({
  isOpen,
  onClose,
  onDrop,
  file,
  onDragOver,
  onDragLeave,
  handleFileSelect,
  title,
  setTitle,
  handleSendMessage,
}) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      handleFileSelect(selectedFile);
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <h2>Envio de Arquivo</h2>
          <CloseButton onClick={onClose}>X</CloseButton>
        </ModalHeader>
        <ModalBody
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {file ? (
            <ModalImage src={file} alt="Pré-visualização do arquivo" />
          ) : (
            <p>clique para selecionar um arquivo</p>
          )}
        </ModalBody>
        {file && (
          <div>
            <input
              type="text"
              placeholder="Digite legenda para o arquivo"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={{ width: "100%", padding: "10px", marginTop: "10px" }}
            />
          </div>
        )}
        <ModalFooter>
          <UploadButton className="send" onClick={handleSendMessage}>
            Enviar
          </UploadButton>
          <UploadButton onClick={onClose}>Fechar</UploadButton>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
