import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2%;
    width: 70vw;
    @media (max-width: 800px) {
        display: ${({$navigation}) => $navigation === 'configScreen' ? 'block' : 'none'};
        width: 100vw;
    }
`;

export const BackButton = styled.div`
    display: none;
    align-items: center;
    margin-bottom: 1%;
    cursor: pointer;
    color: ${({theme}) => theme.colors.Text};
    font-size: 1.5rem;
    font-weight: 500;
    @media (max-width: 800px) {
        display: flex;
    }
`;