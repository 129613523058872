import { useCallback, useEffect, useState } from 'react';
import { Container, DialogContainer, DialogOverlay, Header, Title, Subtitle, ListContacts, DivRowSupContacts, Contact, ContactText, DivRowContact, DivButton, Button, ButtonCancel, ButtonCadastro, NoContacts, XStyled } from './styles.js';
import * as DialogRadix from '@radix-ui/react-dialog';
import Input from '../../../../../../../../shared/components/Input/index.jsx';
import ContactService from '../../../../../../../../shared/services/Contact/ContactService.js';
import { XCircle } from 'phosphor-react';
import DialogCadastro from './components/DialogCadastro/index.jsx';
import { useToast } from '../../../../../../../../hook/toast.jsx';

const Dialog = ({ id, open, onChangeOpen, showIconClose = true, update, setUpdate, contactsSelecteds }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [searchContact, setSearchContact] = useState('');
    const [contacts, setContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const { addToast } = useToast();

    const handleSelectContact = (contact) => {
        const index = selectedContacts.findIndex((selectedContact) => selectedContact.id_contact === contact.id_contact);
        if (index === -1) {
            setSelectedContacts([...selectedContacts, contact]);
        } else {
            setSelectedContacts(selectedContacts.filter((selectedContact) => selectedContact.id_contact !== contact.id_contact));
        }
    };

    const handleCloseDialog = () => {
        onChangeOpen(false);
        setSelectedContacts([]);
    };

    const filteredContactsByNameOrNumber = () => {
        if (searchContact) {
            return contacts.filter((contact) => {
                const name = contact.full_name_contact.toLowerCase();
                const number = contact.id_contact.substring(0, 12).toLowerCase();
                const search = searchContact.toLowerCase();
                if (contacts.length === 0) return false;
                return name.includes(search) || number.includes(search);
            });
        } else {
            return contacts;
        }
    };

    useEffect(() => {
        const getContacts = async () => {
            const response = await ContactService.getAllContacts();
            setContacts(response.data.filter((contact) => contactsSelecteds.findIndex((contactSelected) => contactSelected.id_contact === contact.id_contact) === -1));
        };
        getContacts();
    }, [contactsSelecteds]);

    const verifySelectedContact = useCallback((contact) => {
        return selectedContacts.findIndex((selectedContact) => selectedContact.id_contact === contact.id_contact) !== -1;
    }, [selectedContacts]);

    const handleSave = async () => {
        const data = {
            "idUserUsersContacts": id,
            "idContactUsersContacts": selectedContacts.map((contact) => contact.id_contact)
        };
        const res = await ContactService.createUserContact(data);
        if (res.status === 201) {
            addToast({
                type: 'success',
                title: 'Contatos cadastrados com sucesso',
                description: 'Os contatos foram cadastrados com sucesso'
            });
            setUpdate(!update);
        } else {
            addToast({
                type: 'error',
                title: 'Erro ao cadastrar contatos',
                description: 'Ocorreu um erro ao cadastrar os contatos'
            });
        }
        handleCloseDialog();
    };

    return (
        <>
            <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
                <DialogRadix.Portal>
                    <DialogRadix.Overlay asChild>
                        <DialogOverlay>
                            <DialogRadix.Content asChild>
                                <DialogContainer>
                                    <DialogRadix.Close asChild>
                                        {showIconClose && (
                                            <XStyled onClick={handleCloseDialog} style={{ cursor: 'pointer' }} />
                                        )}
                                    </DialogRadix.Close>
                                    <Container>
                                        <Header>
                                            <Title>Contatos</Title>
                                            <Subtitle>Lista de contatos cadastrados</Subtitle>
                                        </Header>
                                        <ListContacts>
                                            <DivRowSupContacts>
                                                <Input
                                                    value={searchContact}
                                                    onChange={(e) => setSearchContact(e.target.value)}
                                                    placeholder="Pesquisar contato"
                                                />
                                            </DivRowSupContacts>
                                            {filteredContactsByNameOrNumber().length === 0 ? (
                                                    <NoContacts>Nenhum contato encontrado</NoContacts>
                                            ) : (
                                                filteredContactsByNameOrNumber().map(contact => (
                                                    <Contact key={contact.id_contact} onClick={() => handleSelectContact(contact)} $isSelected={verifySelectedContact(contact)}>
                                                        <DivRowContact>
                                                            <ContactText>{contact.full_name_contact}</ContactText>
                                                            {verifySelectedContact(contact) && <XCircle />}
                                                        </DivRowContact>
                                                    </Contact>
                                                ))
                                            )}
                                        </ListContacts>
                                        <DivButton>
                                            <ButtonCancel onClick={handleCloseDialog}>Cancelar</ButtonCancel>
                                            <ButtonCadastro onClick={() => setOpenDialog(true)}>Cadastrar</ButtonCadastro>
                                            <Button onClick={() => handleSave()}>Salvar</Button>
                                        </DivButton>
                                    </Container>
                                </DialogContainer>
                            </DialogRadix.Content>
                        </DialogOverlay>
                    </DialogRadix.Overlay>
                </DialogRadix.Portal>
            </DialogRadix.Root>
            <DialogCadastro open={openDialog} onChangeOpen={setOpenDialog} />
        </>
    );
};

export default Dialog;
