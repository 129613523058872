import React, { useState } from 'react'
import { Container, MenuContainer } from './styles'
import SearchMenu from './components/SearchMenu'
import TabsMenu from './components/Tabs'
import { useSession } from '../../../../hook/session'

const LeftMenu = ({tab, setTab, navigation, setNavigation}) => {
    const { verifyIsRoot } = useSession()
    const [searchTerm, setSearchTerm] = useState('')
    const options = () => {
        if (verifyIsRoot()) {
            return [
                { label: 'Geral', value: '1' },
                { label: 'Gerenciamento', value: '2' },
                { label: 'Notificações', value: '3' },
                { label: 'Anotações', value: '4' },
                { label: 'Segurança', value: '5' },
                { label: 'Sobre', value: '6' }
            ]
        } else {
            return [
                { label: 'Geral', value: '1' },
                { label: 'Notificações', value: '3' },
                { label: 'Anotações', value: '4' },
                { label: 'Segurança', value: '5' },
                { label: 'Sobre', value: '6' }
            ]
        }
    }
    
    const filteredOptions = options().filter(option => option.label.toLowerCase().includes(searchTerm.toLowerCase()))

  return (
    <Container $navigation={navigation}>
        <SearchMenu setSearchTerm={setSearchTerm} />
        <MenuContainer $navigation={navigation}>
            <TabsMenu tab={tab} setTab={setTab} options={filteredOptions} setNavigation={setNavigation} />
        </MenuContainer>
    </Container>
  )
}

export default LeftMenu