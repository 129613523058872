import { Container } from "./styles";
import Chat from "./components/Chat";
import MenuLateral from "./components/MenuLateral";
//import MenuLateralDireito from './components/MenuLateralDireito'
import { useState, useEffect, useRef, useCallback } from "react";
import { extractUsername, formatMessage, formatTimeSentWithDate } from "../../utils/format";

const Home = ({ socket }) => {
  const [navigation, setNavigation] = useState("contacts");
  const [lateralContacts, setLateralContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [messages, setMessages] = useState({});
  const currentMessages = messages[selectedContact] || [];
  const messagesRef = useRef(messages);
  const messagesEndRef = useRef(null);
  messagesRef.current = messages;

  const handleChangeName = useCallback((err, response) => {
    if (err || !response || !response.id_contact || !response.full_name_contact)
      return;
    const id = response.id_contact;
    const newName = response.full_name_contact;

    setLateralContacts((prev) =>
      prev.map((contact) =>
        contact.id === id ? { ...contact, name: newName } : contact
      )
    );
  }, []);

  const handleNewContact = useCallback((contact) => {
    const newContact = {
      id: contact.id_contact,
      image: contact.picture_contact,
      // lastMessage: "",
      lastMessageTimestamp: new Date(),
      name: contact.full_name_contact,
      newMessages: true,
      number:contact.full_name_contact,
      time: formatTimeSentWithDate(),
      unreadMessageCount: 1,
    };

    setLateralContacts((prev) => [...prev, newContact]);
  });

  const handleNewMessage = useCallback((message) => {
    const contactId =
      message.from_contact_message || message.to_contact_message;
    const typeMessage = message.type_message;
    console.log("tipo de mensagem: ",typeMessage);
    switch (typeMessage) {
      case "chat":
        const lastMessageText = message.content_message;
        setMessages((prev) => ({
          ...prev,
          [contactId]: [
            ...(prev[contactId] || []),
            {
              isMine: message.from_contact_message === null,
              message: formatMessage(message.content_message),
              fileName: message.file_name_message,
              fileType: message.file_type_message,
              sendDateMessage: message.send_date_message,
              userName: extractUsername(message.content_message),
              isNoteMessage: message.is_note_message,
            },
          ],
        }));

        setLateralContacts((prev) =>
          prev.map((contact) =>
            contact.id === contactId
              ? {
                  ...contact,
                  lastMessage: lastMessageText,
                  unreadMessageCount:
                    contact.id === selectedContact
                      ? 0
                      : contact.unreadMessageCount + 1,
                  lastMessageTimestamp: new Date(message.send_date_message),
                }
              : contact
          )
        );

        break;
      case "image":
        setMessages((prev) => ({
          ...prev,
          [contactId]: [
            ...(prev[contactId] || []),
            {
              isMine: message.from_contact_message === null,
              message: formatMessage(message.content_message),
              fileName: message.file_name_message,
              fileType: message.file_type_message,
              sendDateMessage: message.send_date_message,
              userName: extractUsername(message.content_message),
              isNoteMessage: message.is_note_message,
            },
          ],
        }));

        setLateralContacts((prev) =>
          prev.map((contact) =>
            contact.id === contactId
              ? {
                  ...contact,
                  lastMessage: "imagem",
                  newMessages: !message.from_contact_message,
                  lastMessageTimestamp: new Date(message.send_date_message),
                }
              : contact
          )
        );
        break;
      case "ptt":
        setMessages((prev) => ({
          ...prev,
          [contactId]: [
            ...(prev[contactId] || []),
            {
              isMine: message.from_contact_message === null,
              message: formatMessage(message.content_message),
              fileName: message.file_name_message,
              fileType: message.file_type_message,
              sendDateMessage: message.send_date_message,
              userName: extractUsername(message.content_message),
              isNoteMessage: message.is_note_message,
            },
          ],
        }));

        setLateralContacts((prev) =>
          prev.map((contact) =>
            contact.id === contactId
              ? {
                  ...contact,
                  lastMessage: "audio",
                  newMessages: !message.from_contact_message,
                  lastMessageTimestamp: new Date(message.send_date_message),
                }
              : contact
          )
        );
        break;
      case "ptt":
          setMessages((prev) => ({
            ...prev,
            [contactId]: [
              ...(prev[contactId] || []),
              {
                isMine: message.from_contact_message === null,
                message: formatMessage(message.content_message),
                fileName: message.file_name_message,
                fileType: message.file_type_message,
                sendDateMessage: message.send_date_message,
                userName: extractUsername(message.content_message),
                isNoteMessage: message.is_note_message,
              },
            ],
          }));
  
          setLateralContacts((prev) =>
            prev.map((contact) =>
              contact.id === contactId
                ? {
                    ...contact,
                    lastMessage: "audio",
                    newMessages: !message.from_contact_message,
                    lastMessageTimestamp: new Date(message.send_date_message),
                  }
                : contact
            )
          );
          break;
      case "video":
        setMessages((prev) => ({
          ...prev,
          [contactId]: [
            ...(prev[contactId] || []),
            {
              isMine: message.from_contact_message === null,
              message: formatMessage(message.content_message),
              fileName: message.file_name_message,
              fileType: message.file_type_message,
              sendDateMessage: message.send_date_message,
              userName: extractUsername(message.content_message),
              isNoteMessage: message.is_note_message,
            },
          ],
        }));

        setLateralContacts((prev) =>
          prev.map((contact) =>
            contact.id === contactId
              ? {
                  ...contact,
                  lastMessage: "vídeo",
                  newMessages: !message.from_contact_message,
                  lastMessageTimestamp: new Date(message.send_date_message),
                }
              : contact
          )
        );
        break;
      case "document":
        setMessages((prev) => ({
          ...prev,
          [contactId]: [
            ...(prev[contactId] || []),
            {
              isMine: message.from_contact_message === null,
              message: formatMessage(message.content_message),
              fileName: message.file_name_message,
              fileType: message.file_type_message,
              sendDateMessage: message.send_date_message,
              userName: extractUsername(message.content_message),
              isNoteMessage: message.is_note_message,
            },
          ],
        }));

        setLateralContacts((prev) =>
          prev.map((contact) =>
            contact.id === contactId
              ? {
                  ...contact,
                  lastMessage: "documento",
                  newMessages: !message.from_contact_message,
                  lastMessageTimestamp: new Date(message.send_date_message),
                }
              : contact
          )
        );
        break;
      default:
        console.log("Tipo de mensagem desconhecido:", typeMessage);
    }
    setLateralContacts((prev) => {
      return prev.sort(
        (a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp
      );
    });
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentMessages]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Conectado ao servidor");
    });
    socket.on("newContact", handleNewContact);
    socket.on("newMessage", handleNewMessage);
    socket.on("changeName", handleChangeName);

    return () => {
      socket.off("connect");
      socket.off("changeName", handleChangeName);
      socket.off("newMessage", handleNewMessage);
      socket.off("newContact", handleNewContact);
    };
  }, [socket, handleChangeName, handleNewMessage, handleNewContact]);

  useEffect(() => {
    socket.emit("viewAllMessagens", selectedContact);
  }, [socket, selectedContact]);

  return (
    <Container>
      <MenuLateral
        socket={socket}
        setMessages={setMessages}
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
        lateralContacts={lateralContacts}
        setLateralContacts={setLateralContacts}
        navigation={navigation}
        setNavigation={setNavigation}
      />
      <Chat
        socket={socket}
        selectedContact={selectedContact}
        lateralContacts={lateralContacts}
        setLateralContacts={setLateralContacts}
        currentMessages={currentMessages}
        messagesEndRef={messagesEndRef}
        navigation={navigation}
        setNavigation={setNavigation}
      />
    </Container>
  );
};

export default Home;
