import { useCallback } from 'react'
import { Container, DialogContainer, DialogOverlay, Header, Title, Label, Button, ButtonCadastro, DivButton, Form } from './styles'
import * as DialogRadix from '@radix-ui/react-dialog'
import { X } from 'phosphor-react'
import Input from '../../../../../../../../../../shared/components/Input/index.jsx'

const Dialog = ({ open, onChangeOpen, showIconClose = true }) => {
  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  const handleSubmit = (event) => {
    event.preventDefault()
    handleCloseDialog()
  }
  
  return (
    <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
      <DialogRadix.Portal>
        <DialogRadix.Overlay asChild>
          <DialogOverlay>
            <DialogRadix.Content asChild>
              <DialogContainer>
                <Container>
                  <Header>
                    <Title>Criar contato:</Title>
                    <DialogRadix.Close asChild>
                  {showIconClose && (
                    <X
                      onClick={handleCloseDialog}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </DialogRadix.Close>
                  </Header>
                  <Form onSubmit={handleSubmit}>
                    <Label>Nome:</Label>
                      <Input 
                        placeholder="Digite o nome"
                        type="text"
                      />
                      <Label>Telefone</Label>
                      <Input 
                        placeholder="Digite o telefone"
                        type="text"
                      />
                    <DivButton>
                      <Button onClick={handleCloseDialog}>Cancelar</Button>
                      <ButtonCadastro
                        type='submit'
                      >Cadastrar</ButtonCadastro>
                    </DivButton>
                  </Form>
                </Container>
              </DialogContainer>
            </DialogRadix.Content>
          </DialogOverlay>
        </DialogRadix.Overlay>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  )
}

export default Dialog
