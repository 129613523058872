import { Container, ContactContainer } from './styles'
import ContactLateral from './components/ContactLateral'
import SearchMenu from './components/SearchMenu'
import imageContato from '../../../../assets/images/imageContato'
import { format, formatMessage, getMessages } from '../../../../utils/format'
import { useCallback, useEffect, useState } from 'react'

const MAX_LAST_MESSAGE_LENGTH = 25
const MenuLateral = ({
  socket,
  setMessages,
  selectedContact,
  setSelectedContact,
  lateralContacts,
  setLateralContacts,
  navigation,
  setNavigation,
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const getBase64Media = async (fileName, fileType) => {
    return new Promise((resolve, reject) => {
      socket.emit("getBase64Media", fileName, fileType, (err, res) => {
        if (!err) {
          resolve(res);
        } else {
          reject(err);
        }
      });
    });
  };

  const handleLateralContacts = useCallback(
    async (data) => {
      const formattedData = await format(data,getBase64Media)
      setLateralContacts(formattedData)
      if (formattedData.length > 0) {
        const firstContactId = formattedData[0].id
        setSelectedContact(firstContactId)
        socket.emit(
          'last100messages',
          { idContact: firstContactId },
          (err, response) => {
            if (!err) {
              setMessages((prev) => ({
                ...prev,
                [firstContactId]: getMessages(response),
              }))
            }
          },
        )
      }
    },
    [socket, setLateralContacts, setSelectedContact, setMessages],
  )

  useEffect(() => {
    socket.on('lateralContacts', handleLateralContacts)
    return () => {
      socket.off('lateralContacts', handleLateralContacts)
    }
  }, [socket, handleLateralContacts])

  const handleContactClick = (contactId) => {
    setSelectedContact(contactId)

    socket.emit(
      'last100messages',
      { idContact: contactId },
      (err, response) => {
        if (!err) {
          const msg = getMessages(response)
          const lastMsg = msg[msg.length - 1]?.image
            ? 'imagem'
            : msg[msg.length - 1]?.message || ''
          setLateralContacts((prev) =>
            prev.map((contact) =>
              contact.id === contactId
                ? { ...contact, newMessages: true, lastMessage: lastMsg }
                : contact,
            ),
          )
          setMessages((prev) => ({
            ...prev,
            [contactId]: msg,
          }))
        }
        
      },
    )
  }

  const filteredContacts = lateralContacts.filter(contact =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <>
      <Container $navigation={navigation}>
        <SearchMenu setSearchTerm={setSearchTerm} />
        <ContactContainer>
          {filteredContacts.map((contact, index) => {
            if (contact.image === `data:image/png;base64, null`) {
              contact.image = imageContato
            }
            const lastMessage = contact.lastMessage || ''
            const limitedLastMessage =
              lastMessage.length > MAX_LAST_MESSAGE_LENGTH
                ? formatMessage(lastMessage).substring(0, MAX_LAST_MESSAGE_LENGTH) + '...'
                : formatMessage(lastMessage)

            return (
              <ContactLateral
                socket={socket}
                key={index}
                contact={contact}
                lastMessage={limitedLastMessage}
                onContactClick={() => {
                  handleContactClick(contact.id)
                  setNavigation('chat')
                }}
                selectedContact={selectedContact}
              />
            )
          })}
        </ContactContainer>
      </Container>
    </>
  )
}

export default MenuLateral
