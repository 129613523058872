import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: ${({ theme }) => theme.colors.OnPrimary};
`;

export const Div = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const ContainerLogin = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    @media (max-width: 450px) {
        max-width: 300px;
    }
`;

export const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    margin-bottom: 1.5rem;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid
    ${({ $focused, $hovered }) =>
      $focused
        ? '#5000ff'
        : $hovered
          ? '#000'
          : '#a6a6a6'};
  border-radius: 0.4rem;
  color: black;
  padding: 3px 8px;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  transition: border 0.3s;
`;

export const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 1rem;
  font-weight: 300;
`

export const Label = styled.h2`
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    margin-bottom: 0.5rem;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const Button = styled.button`
    background-color: ${({ theme }) => theme.colors.Jit};
    color: white;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 1rem;

    &:hover {
        background-color: ${({ theme }) => theme.colors.JitHover};
    }
`

export const ErrorSpan = styled.span`
position: absolute;
  color: red;
  font-size: 0.9rem;
  margin-top: 4.5rem;
`

export const ContainerImg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
`

export const Img = styled.img`
    width: 100%;
    height: 100%;
    @media (max-width: 1000px) {
        width: 60%;
    }   
`

export const AStyled = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const MarkName = styled.h1`
    font-size: 5rem;
    font-weight: 700;
    color: white;
    margin-bottom: 1rem;
    @media (max-width: 1000px) {
        font-size: 3rem;
    }
`