import styled from 'styled-components'
import * as Popover from '@radix-ui/react-popover';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15%;
  border-right: 1px solid ${({ theme }) => theme.colors.Primary};
  gap: 8px;

  input {
    width: 100%;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.Primary};
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }
`

export const HeaderConfig = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.OnPrimary};
  justify-content: center;
  align-items: center;
  height: 50%;
`

export const BottomConfig = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.BackgroundMessage};
  height: 50%;
  align-items: center;
`

export const ImageContact = styled.img`
  width: auto;
  height: 90%;
  border-radius: 100%;
  padding-left: 0.5rem;
`

export const IconsEnd = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding-right: 0.5rem;
`

export const PopoverContent = styled(Popover.Content)`
  background-color: ${({ theme }) => theme.colors.Popover};
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
`

export const PopoverArrow = styled(Popover.Arrow)`
  fill: ${({ theme }) => theme.colors.Popover};
`

export const Item = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
  transition: 0.3s ease;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.PopoverHover};
  }
`

export const ItemText = styled.p`
  cursor: pointer;
`