import { Container } from './styles'

const Input = ({ type, placeholder, ...res }) => {
  return (
    <Container>
      <input type={type} placeholder={placeholder} {...res} />
    </Container>
  )
}

export default Input
